import React from "react";
import "./chat.css";
import video_ from "../../assets/video.mp4"
import imageOne from "../../assets/flow-image-one.gif"
import imageTwo from "../../assets/flowTwo.gif"
import imageThree from "../../assets/flowThree.gif"

const Chat = () => {

  return (
    <div className="chat-ui">
      <div data-aos={"zoom-out"} data-aos-delay={"150"} className="hero blend-section_inset">
        <video autoPlay loop muted playsInline className="hero-video">
          <source src={video_} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero-content">
          <p>Automatically Build and Optimize <span className="text-gradient">Your RAG</span> Apps</p>
          <p className="ai-message">
            Meet Queryloop: the comprehensive solution for building, evaluating, and deploying your LLM apps—our unique optimization takes the guesswork out of building production-ready Generative AI apps
          </p>
          <div className="cta_flow">
            <button onClick={() => window.location.replace("https://app.queryloop-ai.com/add/organization")}>Try for free</button>
            <button onClick={() => window.location.replace("https://calendly.com/operations-queryloop-ai/demo?guests=samee@queryloop-ai.com")}>Book a demo &nbsp;	&rarr;</button>
            {/* {<button>Book a demo &nbsp;	&rarr;</button>} */}
          </div>
        </div>

      </div>
      <div className="blend-section"></div>
      <div className="queryloop-flow">
        <div className="flow-one flow_1">
          <img src={imageOne} alt="flowImage" />
        </div>
        <div className="flow-one flow-two" data-aos={"zoom-out"} data-aos-delay={"150"}>
          <img src={imageTwo} alt="flowImage" />
        </div>
        <div className="flow-four" data-aos={"zoom-out"} data-aos-delay={"150"}>
          <img src={imageThree} alt="flowImage" />
        </div>
      </div>


    </div>
  );
};

export default Chat;
