import React from 'react';
import './ConsultancyPage.css';
import CounsultanyBg from "../../assets/consultancybg1.svg"
import ConsultancyTeams from "../../assets/consultancyteams.gif"
const ConsultancyPage = () => {

    const openEmailClient = () => {
        const recipient = 'support@queryloop-ai.com';
        const subject = 'Regarding your services';
        const body = 'Dear QueryLoop AI team,\n\nI am interested in learning more about your services.\n\nRegards, [Your Name]';

        const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        // Open the default email client with the pre-filled recipient, subject, and body
        window.open(mailtoLink);
    };

    return (
        <div data-aos={"fade-up"} data-aos-delay={"150"} className="consultancy-page">
            <section className="section section_hero">
                <img src={CounsultanyBg} alt='consultancy' className="background-image" />
                <h1 className="heading text-gradient">Building AI that exhibits human-level performance</h1>
                <p className="subheading">QueryloopAI Lab's mission is to build and deploy embodied artificial intelligence that exhibits expert human-level performance and is capable of addressing challenging societal problems.</p>
            </section>

            <section data-aos={"fade-up"} data-aos-delay={"150"} className="section-teams">
                <div className='section-teams_info'>
                    <h2 className="heading">How does QueryloopAI Lab plan to achieve its mission?</h2>
                    <p className="subheading">QueryloopAI Lab will integrate and enhance state-of-the-art language, vision, and speech models, deploying these models in simulated and real-world agents that interact with their environments. All of QueryloopAI Lab's work is open-source, allowing for public scrutiny and community-led improvements.</p>
                </div>
            </section>

            <section data-aos={"fade-up"} data-aos-delay={"150"} className="section section-key-projects">

                <h2 className="heading text-gradient">What are the key projects that QueryloopAI Lab is working on?</h2>
                <div className='section-why_info'>
                    <p className="subheading">- Building Agentic systems on top of state of the art models</p>
                    <p className="subheading">- Exploring inference time optimization to maximally leverage existing models</p>
                    <p className="subheading">- Building smaller, cheaper and more efficient models.</p>
                    <p>See our <a href='https://huggingface.co/QueryloopAI'>Hugging Face</a> and <a href='https://github.com/QueryloopAI/QueryloopAI-Lab'>GitHub</a> for details.</p>
                </div>
                <div className='subheading-sub-section'>

                </div>

            </section>

            <section data-aos={"fade-up"} data-aos-delay={"150"} className="section section-why">
                <h2 className="heading text-gradient">Interested in joining us?</h2>
                <p className="subheading">Get in touch to learn more about Queryloop AI Lab.</p>
                <button className="btn-email" onClick={openEmailClient}>Email Us</button>
            </section>

        </div>
    )
}

export default ConsultancyPage;
