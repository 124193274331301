import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import Prism from 'prismjs';

import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';


const CodeBlock = ({ code, language }) => {
    return (
        <SyntaxHighlighter language={language} style={atomDark}>
            {code}
        </SyntaxHighlighter>
    );
};

export default CodeBlock;
