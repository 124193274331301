import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    IconButton,
    Collapse,
    Box,
    Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import colors from "../../../utils/colors";

const SubscriptionComparisonTable = () => {
    const [open, setOpen] = useState({});

    const toggleOpen = (index) => {
        setOpen((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    const expandAll = () => {
        const allOpen = {};
        features.forEach((_, index) => {
            allOpen[index] = true;
        });
        setOpen(allOpen);
    };

    const collapseAll = () => {
        const allClosed = {};
        features.forEach((_, index) => {
            allClosed[index] = false;
        });
        setOpen(allClosed);
    };

    const plans = [
        {
            name: "Starter",
            features: {
                "Prompt Optimization": "Yes",
                "Retrieval Optimization": "Yes",
                "Set your own Pinecone and OpenAI key": "Yes",
                "Use QueryLoop keys": "Limited usage",
                "Access Usage Analytics": "Only of your keys",
                "Add datasets": "Upto 5 datasets with combined limit of 5 MB",
                "Create LLM applications": "Only 1",
                "Number of Users": "Only 1",
                "Golden Questions and Answers": "Upto 5",
                "Embedding Optimization Access": "No",
                "Deploy LLM applications": "No",
                "Finetuning & other Models Access": "No",
                "LLM experts Custom Support": "No",
            },
        },
        {
            name: "QueryLoop Pro",
            features: {
                "Prompt Optimization": "Yes",
                "Retrieval Optimization": "Yes",
                "Set your own Pinecone and OpenAI key": "Yes",
                "Use QueryLoop keys": "Yes",
                "Access Usage Analytics": "Yes",
                "Add datasets": "Yes",
                "Create LLM applications": "Unlimited",
                "Number of Users":
                    "Support for 2 users, USD 30/month per additional user",
                "Golden Questions and Answers": "Unlimited",
                "Embedding Optimization Access": "Yes",
                "Deploy LLM applications": "Yes",
                "Finetuning & other Models Access": "Yes",
                "LLM experts Custom Support": "No",
            },
        },
        {
            name: "QueryLoop Enterprize",
            features: {
                "Prompt Optimization": "Yes",
                "Retrieval Optimization": "Yes",
                "Set your own Pinecone and OpenAI key": "Yes",
                "Use QueryLoop keys": "Yes",
                "Access Usage Analytics": "Yes",
                "Add datasets": "Yes",
                "Create LLM applications": "Unlimited",
                "Number of Users": "Unlimited",
                "Golden Questions and Answers": "Unlimited",
                "Embedding Optimization Access": "Yes",
                "Deploy LLM applications": "Yes",
                "Finetuning & other Models Access": "Yes",
                "LLM experts Custom Support": "Yes",
            },
        },
    ];

    const features = [
        {
            name: "Prompt Optimization",
            desc: "Prompt Optimization focuses on enhancing the instructions given to a language model (LLM) to improve the quality of its outputs.",
        },
        {
            name: "Retrieval Optimization",
            desc: "Retrieval optimization involves the strategic identification of optimal chunking methodologies, embedding techniques, distance metrics, query preprocessing methods, and reranking approaches to significantly enhance information retrieval efficacy.",
        },
        {
            name: "Set your own Pinecone and OpenAI key",
            desc: "By providing your own OpenAI and Pinecone keys, you can unlock additional features.",
        },
        {
            name: "Use QueryLoop keys",
            desc: "You can use Queryloop's OpenAI and Pinecone keys with certain limitations in the Starter package, and without limitations in the Pro package",
        },
        {
            name: "Access Usage Analytics",
            desc: "Access to monthly usage costs for users and Queryloop in both experimented and deployed LLM applications.",
        },
        {
            name: "Add datasets",
            desc: "Queryloop supports multiple data types. In the Starter package, you can add up to five datasets with a combined limit of 5 MB; however, by providing your own Pinecone and OpenAI keys or subscribing to the Pro package, you can remove this limit.",
        },
        {
            name: "Create LLM applications",
            desc: "Creating an LLM application involves adjusting retrieval settings, optimizing prompts and LLM parameters, and LLM fine-tuning to enhance performance and deliver accurate, contextually relevant results.",
        },
        {
            name: "Number of Users",
            desc: "Numbers of users allowed to register in the organization",
        },
        {
            name: "Golden Questions and Answers",
            desc: "Golden responses are predefined benchmark answers used to evaluate the performance and accuracy of LLM generated responses.",
        },
        { name: "Embedding Optimization Access", desc: "" },
        {
            name: "Deploy LLM applications",
            desc: "Deploy the LLM applications based on the selected parameters.",
        },
        {
            name: "Finetuning & other Models Access",
            desc: "Access fine-tuning and advanced foundation models, including Meta's, Mixtral's, Google's, and more.",
        },
        {
            name: "LLM experts Custom Support",
            desc: "Personalized support from LLM experts to enhance your application experience.",
        },
    ];

    return (
        <TableContainer
            component={Paper}
            sx={{
                backgroundColor: colors.extremeBlack,
                // boxShadow: `0px 0px 40px ${colors.secondaryBlue}`,
                // border: `2px solid  ${colors.secondaryBlue}`,
                // borderRadius: "12px",
                marginTop: "20px",
                paddingBottom: "20px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px 20px",
                }}
            >
                <Button
                    onClick={expandAll}
                    sx={{
                        color: colors.extremeWhite,
                        bgcolor: colors.primaryBlue,
                        textTransform: "none",
                    }}
                >
                    Expand All
                </Button>
                <Typography
                    sx={{
                        color: colors.extremeWhite,
                        fontWeight: "bold",
                        fontSize: "1.4rem",
                    }}
                >
                    Subscription Comparison Table
                </Typography>
                <Button
                    onClick={collapseAll}
                    sx={{
                        color: colors.extremeWhite,
                        bgcolor: colors.primaryBlue,
                        textTransform: "none",
                    }}
                >
                    Collapse All
                </Button>
            </Box>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                color: colors.extremeWhite,
                                fontWeight: "bold",
                                fontSize: "1rem",
                                bgcolor: colors.primaryBlue,
                            }}
                        >
                            Feature
                        </TableCell>
                        {plans.map((plan, index) => (
                            <TableCell
                                key={index}
                                align="center"
                                sx={{
                                    color: colors.extremeWhite,
                                    fontWeight: "bold",
                                    fontSize: "1.1rem",
                                    bgcolor: colors.primaryBlue,
                                }}
                            >
                                {plan.name}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {features.map((feature, rowIndex) => (
                        <React.Fragment key={rowIndex}>
                            <TableRow
                                sx={{
                                    backgroundColor: colors.darkGray,
                                    "&:hover": {
                                        backgroundColor: colors.lightGray,
                                    },
                                }}

                            >
                                <TableCell
                                    sx={{
                                        color: colors.extremeWhite,
                                        borderBottom: `1px solid ${colors.lightGray}`,
                                        padding: "12px",
                                    }}
                                >
                                    <IconButton
                                        onClick={
                                            feature.desc !== "" ? () => toggleOpen(rowIndex) : () => null
                                        }
                                        sx={{
                                            color:
                                                feature.desc !== ""
                                                    ? colors.extremeWhite
                                                    : "transparent",
                                        }}
                                    >
                                        {open[rowIndex] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                    {feature.name}
                                </TableCell>
                                {plans.map((plan, colIndex) => (
                                    <TableCell
                                        key={colIndex}
                                        align="center"
                                        sx={{
                                            color: colors.extremeWhite,
                                            borderBottom: `1px solid ${colors.lightGray}`,
                                            padding: "12px",
                                        }}
                                    >
                                        {plan.features[feature.name] === "Yes" ? (
                                            <CheckIcon sx={{ color: colors.extremeWhite }} />
                                        ) : plan.features[feature.name] === "No" ? (
                                            <CloseIcon sx={{ color: colors.lightGray }} />
                                        ) : (
                                            plan.features[feature.name]
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                            {feature.desc !== "" && (
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            paddingBottom: 0,
                                            paddingTop: 0,
                                            bgcolor: colors.lightGray,
                                        }}
                                        colSpan={plans.length + 1}
                                    >
                                        <Collapse in={open[rowIndex]} timeout="auto" unmountOnExit>
                                            <Box margin={1}>
                                                <Typography
                                                    variant="body2"
                                                    sx={{ color: colors.extremeWhite }}
                                                >
                                                    {feature.desc}
                                                </Typography>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SubscriptionComparisonTable;
